
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmButton,
    TmAlert,
    FlowStep,
    TmTooltip,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['step-change'],
  setup() {
    const copyLink = ref('https://wa.me/3725232960')
    const copiedStatus = ref(false)
    const copyText = () => {
      copiedStatus.value = true
      copyToClipboard(copyLink.value)
      setTimeout(() => {
        copiedStatus.value = false
      }, 3000)
    }

    return {
      copyLink,
      copiedStatus,
      copyText,
    }
  },
})
