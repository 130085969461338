
import { defineComponent, ref } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import ConnectAccountStep from '@/components/pages/chats/settings/channels/whatsAppFlow/ConnectAccountStep.vue'
import NumberStep from '@/components/pages/chats/settings/channels/whatsAppFlow/NumberStep.vue'

export default defineComponent({
  components: {
    TmButton,
    DetailsHero,
    PageBlock,
    FlowStepList,
    ConnectAccountStep,
    NumberStep,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', name: 'base.chats.main' },
      { label: 'Messenger settings', name: 'base.chats.settings' },
      { label: 'WhatsApp', name: 'base.chats.settings.whatsApp' },
      { label: 'Connect a new or existing WhatsApp Business Account' },
    ]
    const currentStep = ref(1)

    return {
      breadcrumbs,
      currentStep,
    }
  },
})
